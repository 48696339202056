<template>
  <div class="container">
    <table-page data-name="mini_users"
                :search="search"
                ref="table"
                :request="fetchUsers">
      <el-table-column prop="openid"
                       show-overflow-tooltip
                       label="OPENID"></el-table-column>
      <el-table-column prop="tel"
                       label="手机号"></el-table-column>
      <el-table-column prop="sex"
                       label="性别"></el-table-column>
      <el-table-column prop="address"
                       label="地区"></el-table-column>
      <el-table-column prop="join_coupon_total"
                       label="参与活动次数"></el-table-column>
      <el-table-column prop="lasted_login_at"
                       show-overflow-tooltip
                       label="最后登录时间"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-tooltip content="解除微信绑定"
                      placement="top">
            <el-button type="danger"
                       size="small"
                       @click="removeBind(scope.row)"
                       icon="el-icon-delete"
                       circle></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </table-page>
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import { fetchUsers, removeBind } from '@/api/user'
export default {
  name: 'UserList',
  data () {
    return {
      isCollapse: true,
      fetchUsers,
      search: [{
        type: 'select',
        key: 'search_type',
        name: '搜索类型',
        value: '',
        list: [
          {
            label: '手机号',
            value: 'TEL'
          }
        ]
      }, {
        type: 'input',
        value: '',
        key: 'search_keyword',
        placeholder: '请输入关键字'
      }]
    }
  },
  components: {
    TablePage
  },
  methods: {
    removeBind (e) {
      this.$confirm('是否解除该手机号与微信账号绑定', {
        type: 'warning'
      }).then(async () => {
        const res = await removeBind({
          user_id: e.id
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '解除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    },
    onSearch (e) {
      this.searchParams = e
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    }
  },
  mounted () { }
}
</script>

<style lang="scss" scoped></style>
